body {
    background-color: coral;
    font-family: 'aAbstractGroovy';
}
.aboutHeader {
    color: rgb(222, 107, 245);
    font-size: 45px;
    text-align: center;
    font-weight: 1000;
    padding-top: 30px;
    margin-left: 10%;
    margin-right: 10%;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.headerPic {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    height: 40%;
    border-radius: 50%;
    padding-top: 25px;
}
.blurb1 {
    padding-top: 45px;
    text-align: left;
    margin: auto;
    font-size: 300%;
    font-family: 'FashionStamp';
    font-weight: 500;
    margin-left: 5%;
    margin-right: 5%;
    text-size-adjust: 25%;
}
