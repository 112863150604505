body {
    font-family: 'aAbstractGroovy';
}
.rsvpBody {
    text-align: center;
    margin: auto;
    margin-left: 10%;
    margin-right: 10%;
}
.subHeader1 {
    color: rgb(255, 241, 116);
    text-align: center;
    font-size: 150%;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}
.subHeading2 {
    color: rgb(255, 241, 116);
    text-align: center;
    font-size: 25px;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}
.buttonContainer {
    text-align: center;
    padding-bottom: 10px;
}
.yesButton {
    text-align: center;
    position: relative;
}
.noButton {
    text-align: center;
    position: relative;
}
