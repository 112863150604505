body {
    font-family: 'aAbstractGroovy';
}

.regLink1 {
    padding-top: 25px;
    font-weight: 500;
    font-size: larger;
    text-align: center;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    padding-bottom: 10px;
}
.cashOptions {
    padding-top: 30px;
    padding-bottom: 15px;
    font-weight: 500;
    font-size: larger;
    text-align: center;
    color: black;
}
.userName {
    padding-bottom: 5px;
    font-weight: 500;
    font-size: larger;
    text-align: center;
    color: ivory;
}
